import React from "react"

export default function Campfire(){
    return (
        <>
        <div id = "campfire-container">
            <h1>This is the future landing page for my Around the Campfire BLOG</h1>
            <p>The Around the Campfire BLOG is at its core, a place to share my transition from the Active Duty Army into Tech, all while building a 25 acre homestead for my family. </p>

        </div>
        
        
        </>
    )
}